<template>
  <div id="app">
    <transition-page
    >
      <router-view
          ref="view"
          id="view"
          :key="$route.fullPath"
      />
    </transition-page>
  </div>
</template>

<script>
  import { EventBus } from "../EventBus";
  import TransitionPage from "./components/TransitionPage";

export default {
  name: 'App',
  components: {
    TransitionPage
  },
  beforeCreate() {
    // const agent = window.navigator.userAgent.toLowerCase();
    // const appName = window.navigator.appName.toLowerCase();
    //
    // if((appName === 'netscape' && agent.indexOf('trident')!== -1) || agent.indexOf('msie') !== -1) {
    //   if(confirm("현재 페이지는 인터넷익스플로러(IE)브라우저를 지원하지 않습니다. " + "크롬 혹은 엣지브라우저에서 정상적으로 작동됩니다. 해당 브라우저로 이동하시겠습니까?")) {
    //     window.location = "microsoft-edge:" + window.location.href;
    //   }
    // }
  },
  created() {
    // if(/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
    //   window.location = 'microsoft-edge:' + window.location;
    //   setTimeout(function () {
    //     window.open('', '_blank', '').close();
    //     window.location = 'https://themonsports.netlify.app';
    //   }, 1);
    // }
  },
  mounted() {
    // let that = this;
    // let slide = that.$route.query.slide;
    // if(slide === '1') {
    //   EventBus.$emit('svg-change', true);
    // } else if (slide === '2') {
    //   EventBus.$emit('svg-change', true);
    // } else if (slide === '3') {
    //   EventBus.$emit('svg-change', true);
    // } else if (slide === '4') {
    //   EventBus.$emit('svg-change', true);
    // }
  },
  methods: {

  }
}
</script>

<style lang="scss">
//@import "./style/app";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-vue/src/index";
</style>
