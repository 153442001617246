<template>
  <b-container id="performance" fluid class="px-0 position-relative">
    <b-container fluid class="px-0 position-absolute">
      <b-row class="pb-sm-0 pb-2">
        <b-col sm="12" class="h-100 pr-sm-3 pr-3 mr-1 mr-sm-0">
          <GlobalNavigationBar
          ></GlobalNavigationBar>
        </b-col>
      </b-row>
    </b-container>
    <hooper
        class="full-screen position-fixed"
        :centerMode="true"
        :transition="600"
        :wheel-control="false"
        :mouse-drag="false"
        :touch-drag="false"
        ref="hooper"
        @afterSlide="disabled=false"
    >
      <slide
          v-for="category in categories"
          :key="'performance_bg_' + category.id"
      >
        <div
            class="w-100 h-100 bg-image bg-color"
            :style="[{backgroundImage: 'url(' + $s3url + category.background_image + ')'},{backgroundColor: category.background_color}]"
        >
          <div class="after"
               :style="{backgroundColor: category.background_color}"
          ></div>
        </div>
      </slide>
    </hooper>
    <b-row class="pb-sm-5 pb-5 px-sm-1 mx-0 px-0 mx-sm-5" align-h="between">
      <b-col md="5" class="py-md-5 py-5 my-sm-5 pr-sm-0">
        <h1
            class="mt-sm-4 pt-sm-3 mt-5 text-white font-brand mb-0 performance-title"
        >
          PERFORMANCE
        </h1>
        <h5
            class="text-white mt-3 fs-mobile-16 lh-30"
        >
          수많은 경험과 데이터로부터 도출된 노하우를 통해 <br class="d-none d-sm-block">최고의 퍼포먼스를 제공합니다.
        </h5>
        <b-row class="mt-4">
          <b-col sm="12" class="mt-2">
            <h4 class="font-brand text-white mb-0">
              Categories <b-button @click="current_category = {}" variant="transparent" class="text-white fs-20 fs-mobile-16 d-inline-flex"><u>전체</u></b-button>
            </h4>
            <b-form-radio
                v-for="(category, index) in categories"
                :key="'category-btn-' + category.id"
                v-model="current_category"
                name="current-category"
                button
                :value="category"
                class="performance-btn mr-3 mt-3"
            >
              <h5
                  class="mb-0 font-brand"
                  :style="{color: [ !overwriteColor ?
                  category.label_color : overwriteColor]}"
              >
                {{category.title}}
              </h5>
            </b-form-radio>
            <hr class="border-1 text-white">
            <b-container fluid>
              <b-row>
                <b-col sm="12" class="px-0">
                  <h4 class="font-brand text-white mb-0">
                    Scopes  <b-button @click="current_tag = []" variant="transparent" class="text-white fs-20 fs-mobile-16 d-inline-flex"><u>전체</u></b-button>
                  </h4>
                  <b-row>
                    <b-col class="">
                      <b-form-checkbox
                          button
                          v-model="current_tag"
                          v-for="tag in tags"
                          :key="'tag_' + tag.id"
                          :value="tag.tag"
                          class="performance-btn tag-btn mr-3 mt-3"
                      >
                        <h5
                            class="mb-0 fs-18 tag-name"
                            :style="{color: overwriteColor}"
                        >
                          {{tag.tag}}
                        </h5>
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                  <hr class="border-1 text-white">
                  <h6
                      class="font-weight-light text-white text-right"
                  >총 <strong class="font-weight-bold">
                    {{performances.length}}</strong>개의 이벤트</h6>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-col>
      <!--          오른쪽 컨텐츠-->
      <b-col md="6" class="w-100 pt-md-5 mt-md-5 pr-sm-0">
        <div class="d-flex justify-content-center mt-5" v-if="isLoading">
          <b-spinner label="Spinning" style="width: 3rem; height: 3rem;" variant="light"></b-spinner>
        </div>
        <div
            class="w-100 mt-md-4 pt-md-3"
            role="tablist"
            v-else
        >
          <b-card
              no-body
              class="mb-4 py-3 br-16 cursor-pointer"
              block
              v-for="(item, index) in performances"
              :key="'performance_' + item.id"
              v-b-toggle="'performance_' + item.id"
              role="tab"
              :aria-controls="'performance_' + item.id"
          >
            <b-row>
              <b-col
                  class="d-inline-flex"
              >
                <h6
                    class="fs-14 font-brand text-white performance-label mb-0 ml-sm-4 ml-3 mr-sm-3 mr-2 fs-mobile-12"
                    :style="{backgroundColor: item.categories.label_color}"
                >
                  {{item.categories.title}}
                </h6>
                <h6
                    class="fs-18 font-weight-light mb-0 ellipsis mt-1 mb-0 text-left fs-mobile-16"
                    :style="{color: item.categories.label_color}"
                >
                  {{item.title}}
                </h6>
                <div class="mr-4">
                  <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.5" d="M2 2L10.1569 8L18 2" stroke="#B61F24" stroke-width="4"/>
                  </svg>
                </div>
              </b-col>
            </b-row>
            <b-collapse
                :id="'performance_' + item.id"
                accordion="my-accordion"
                role="tabpanel"
            >
              <b-card-body class="pb-0">
                <b-card-text class="fs-14 fs-mobile-14">
                  {{item.content}}
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { Hooper, Slide } from "hooper";
import 'hooper/dist/hooper.css';
import {EventBus} from "../../EventBus";
import GlobalNavigationBar from "../components/GlobalNavigationBar";

export default {
  name: 'Performance',
  components: { Hooper, Slide, GlobalNavigationBar },
  data() {
    return {
      isOpened: false,
      isLoading: false,
      disabled: false,
      current_category: {},
      current_tag: [],

      performances: [],
      categories: [],
      tags: [],
      overwriteColor: false,
    }
  },
  watch:{
    'current_category': function (val) {
      // 배경 슬라이드 이동
      this.category_change(val);
      this.fetch();

      this.overwriteColor = val.label_color;
      if(val === 'undefined') {
        return this.overwriteColor = false;
      }
    },
    'current_tag': function (val) {
      this.fetch();
    },
    'disabled': function (val) {
    }
  },
  created() {
    let that = this;
    this.axios.get('/themon/performances').then(res=>{
      that.performances = res.data
    })
    this.axios.get('/themon/performance_categories').then(res=>{
      that.categories = res.data
    })
    this.axios.get('/themon/tags').then(res=>{
      that.tags = res.data
    })
    const myLocation = this.$route.path
    if(myLocation === '/performance') {
      EventBus.$emit('gnb_bg_class', 'white');
      EventBus.$emit('hamburger_color', 'white');
    }
  },
  updated() {
    this.disabled = false;
  },
  methods: {
    fetch(){
      this.isLoading = true,
      this.axios.get('/themon/performances', {
        params: {
          category : this.current_category.id,
          tags: this.current_tag.toString(),
        }
      }).then(res=>{
        this.isLoading = false,
        this.performances = res.data
      })
    },
    category_change(filter){
      this.disabled = true;
      for (let i = 0; i < this.categories.length; i++) {
        if (this.categories[i] === filter) {
          this.$refs.hooper.slideTo(i)
        }
      }
    },
  },
}
</script>
