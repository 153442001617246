<template>
    <b-row class="pt-sm-4">
        <b-col cols="2" sm="2" md="12" lg="1" class="px-0">
           <b-container fluid class="w-100">
             <b-row align-h="between">
               <b-col class="logo pl-md-0 pl-lg-3" md="12" lg="10" xl="12">
                 <svg :width="[isMobile ? '120' : '209']" :height="[isMobile ? '87' : '109']" viewBox="0 0 167 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <g clip-path="url(#clip0)">
                     <path d="M9.59095 75.0132V77.0708H6.13838V86.7897H4.05358V77.0708H0.596863V75.0132H9.59095Z" fill="white"/>
                     <path d="M13.6072 75.0132V79.8129H19.6586V75.0132H21.7434V86.7897H19.6586V81.8705H13.6072V86.7897H11.539V75.0132H13.6072Z" fill="white"/>
                     <path d="M34.0574 75.0132V77.0708H28.1304C27.4963 77.0708 27.0445 77.2027 26.7626 77.4708C26.4808 77.7388 26.3399 78.0975 26.3399 78.5511C26.3399 79.483 26.9409 79.9448 28.147 79.9448H33.9704V81.8127H28.0931C26.887 81.8127 26.286 82.291 26.286 83.2435C26.286 83.7095 26.4311 84.0724 26.7253 84.3321C27.0196 84.596 27.4673 84.7239 28.0765 84.7239H34.074V86.7814H28.0765C27.3761 86.7814 26.7751 86.699 26.2777 86.5382C25.7803 86.3732 25.3742 86.1506 25.0509 85.866C24.7276 85.5815 24.4955 85.2393 24.3504 84.8476C24.2053 84.4517 24.1307 84.027 24.1307 83.5734C24.1307 82.9013 24.2758 82.3364 24.5701 81.8911C24.8644 81.4416 25.283 81.1035 25.8342 80.8685C24.7234 80.4149 24.168 79.5325 24.168 78.2171C24.168 77.7512 24.2426 77.3223 24.396 76.9265C24.5494 76.5306 24.7856 76.1925 25.1048 75.908C25.428 75.6235 25.8342 75.4008 26.3316 75.2441C26.829 75.0874 27.4258 75.0091 28.1304 75.0091H34.0574V75.0132Z" fill="white"/>
                     <path d="M42.5416 86.7897H40.3325L43.9964 76.9842C44.2285 76.3121 44.556 75.776 44.9704 75.3884C45.3849 74.9967 45.99 74.8029 46.7858 74.8029C47.6645 74.8029 48.3194 75.0379 48.7587 75.508C49.1981 75.9781 49.4136 76.5966 49.4136 77.3677V84.328C49.4136 84.5259 49.4675 84.6867 49.5794 84.8063C49.6913 84.93 49.8364 84.9919 50.0104 84.9919C50.3379 84.9919 50.5575 84.8269 50.6777 84.5012L53.5542 76.9801C53.7904 76.308 54.1137 75.7719 54.5282 75.3843C54.9427 74.9926 55.5478 74.7988 56.3436 74.7988C57.2223 74.7988 57.8772 75.0338 58.3165 75.5039C58.7558 75.974 58.9755 76.5925 58.9755 77.3635V86.7856H56.8866V77.4708C56.8866 77.2605 56.8327 77.0955 56.7208 76.9718C56.6089 76.8522 56.4679 76.7904 56.2897 76.7904C56.1364 76.7904 56.0079 76.8358 55.896 76.9306C55.7841 77.0254 55.7012 77.145 55.6431 77.2976L52.7667 84.8146C52.5346 85.4908 52.2071 86.0227 51.7844 86.4103C51.3658 86.802 50.7565 86.9958 49.9607 86.9958C49.0862 86.9958 48.4271 86.7608 47.9961 86.2907C47.565 85.8207 47.3495 85.1939 47.3495 84.4146V77.4708C47.3495 77.2605 47.2915 77.0955 47.1713 76.9718C47.0552 76.8522 46.9102 76.7904 46.7319 76.7904C46.5703 76.7904 46.4335 76.8358 46.3299 76.9306C46.2263 77.0254 46.1434 77.145 46.0854 77.2976L42.5416 86.7897Z" fill="white"/>
                     <path d="M68.3592 75.0132C70.0876 75.0132 71.4139 75.4998 72.3299 76.477C73.2459 77.4543 73.706 78.9346 73.706 80.9262C73.706 82.9055 73.2459 84.3775 72.3299 85.3383C71.4139 86.3032 70.0876 86.7856 68.3592 86.7856H66.7469C65.0186 86.7856 63.6923 86.3032 62.7763 85.3383C61.8561 84.3734 61.4002 82.9013 61.4002 80.9262C61.4002 78.9387 61.8561 77.4543 62.7763 76.477C63.6923 75.4998 65.0186 75.0132 66.7469 75.0132H68.3592ZM66.7428 77.0708C65.5947 77.0708 64.7657 77.3718 64.2435 77.978C63.7213 78.5841 63.4643 79.5655 63.4643 80.9262C63.4643 82.2746 63.7213 83.2477 64.2352 83.8415C64.7492 84.4352 65.5864 84.7321 66.7428 84.7321H68.3551C69.4866 84.7321 70.3156 84.4352 70.8378 83.8332C71.3559 83.2353 71.617 82.2622 71.617 80.9097C71.617 79.5614 71.36 78.5841 70.8461 77.978C70.3321 77.3718 69.5032 77.0708 68.3551 77.0708H66.7428Z" fill="white"/>
                     <path d="M78.1947 86.7897H76.1265V77.3677C76.1265 76.6007 76.3503 75.9822 76.7938 75.508C77.2373 75.0379 77.9211 74.8029 78.8454 74.8029C79.6619 74.8029 80.2878 74.9967 80.7147 75.3884C81.1416 75.776 81.4773 76.308 81.7219 76.9842L85.1786 84.5053C85.249 84.6455 85.3402 84.761 85.4604 84.8558C85.5765 84.9465 85.734 84.996 85.9329 84.996C86.1567 84.996 86.3267 84.9341 86.451 84.8104C86.5712 84.6909 86.6334 84.5301 86.6334 84.3321V75.0173H88.7223V84.4187C88.7223 85.198 88.4985 85.8248 88.0467 86.2949C87.595 86.7649 86.9152 87 86.0034 87C85.5931 87 85.2325 86.9505 84.9258 86.8515C84.6149 86.7526 84.3455 86.6083 84.1175 86.4145C83.8896 86.2248 83.6948 85.9939 83.529 85.7258C83.3632 85.4578 83.2264 85.1568 83.1062 84.8187L79.6536 77.3017C79.5003 76.9636 79.2557 76.7945 78.9159 76.7945C78.6838 76.7945 78.5055 76.8564 78.3812 76.976C78.2569 77.0997 78.1947 77.2646 78.1947 77.4749V86.7897Z" fill="white"/>
                     <path d="M105.376 75.0132V77.0708H99.308C98.7692 77.0708 98.3796 77.2028 98.1309 77.4625C97.8864 77.7223 97.7621 78.0604 97.7621 78.4645C97.7621 78.6377 97.7869 78.8109 97.8408 78.98C97.8947 79.149 97.9859 79.3016 98.1227 79.4335C98.2553 79.5655 98.4335 79.6727 98.6573 79.7469C98.8811 79.8211 99.1671 79.8624 99.5153 79.8624H102.288C103.096 79.8624 103.755 79.9655 104.269 80.1675C104.783 80.3695 105.189 80.6335 105.488 80.9633C105.786 81.2891 105.989 81.6602 106.101 82.0808C106.213 82.5014 106.267 82.9178 106.267 83.3384C106.267 83.8373 106.197 84.2992 106.056 84.7156C105.915 85.1362 105.695 85.4991 105.388 85.8042C105.086 86.1135 104.696 86.3526 104.224 86.5299C103.751 86.7031 103.175 86.7897 102.495 86.7897H95.9011V84.7321H102.566C103.129 84.7321 103.54 84.5961 103.801 84.3239C104.062 84.0518 104.195 83.6972 104.195 83.2683C104.195 83.0828 104.166 82.8972 104.108 82.7199C104.05 82.5385 103.95 82.3818 103.809 82.2498C103.668 82.1179 103.478 82.0065 103.237 81.9282C102.997 81.8457 102.694 81.8045 102.334 81.8045H99.5816C98.7858 81.8045 98.1392 81.7055 97.6336 81.5076C97.1321 81.3097 96.7383 81.0499 96.4482 80.7242C96.1622 80.3984 95.9632 80.0314 95.8513 79.6273C95.7394 79.2191 95.6855 78.815 95.6855 78.4068C95.6855 77.9202 95.7519 77.4667 95.8886 77.0543C96.0213 76.642 96.2409 76.2832 96.5394 75.9822C96.8378 75.6812 97.2191 75.4462 97.6875 75.2771C98.1558 75.1081 98.7153 75.0256 99.3702 75.0256H105.376V75.0132Z" fill="white"/>
                     <path d="M114.602 75.0132C115.269 75.0132 115.841 75.108 116.322 75.3018C116.803 75.4915 117.197 75.7513 117.508 76.0688C117.818 76.3904 118.046 76.7656 118.192 77.1945C118.337 77.6233 118.411 78.0728 118.411 78.5387C118.411 78.9799 118.345 79.4211 118.212 79.8623C118.08 80.3035 117.86 80.6994 117.553 81.0499C117.251 81.4004 116.857 81.6849 116.368 81.9034C115.883 82.1261 115.294 82.2333 114.606 82.2333H110.926C110.727 82.2333 110.627 82.3323 110.627 82.5302V86.7856H108.559V82.1261C108.559 81.4622 108.704 80.988 108.998 80.6953C109.293 80.4066 109.765 80.2582 110.42 80.2582H114.681C114.975 80.2582 115.224 80.2128 115.435 80.118C115.647 80.0273 115.817 79.9036 115.945 79.751C116.074 79.5984 116.169 79.4294 116.235 79.2356C116.297 79.0459 116.331 78.8438 116.331 78.6336C116.331 78.2047 116.198 77.8336 115.937 77.5244C115.671 77.2192 115.261 77.0625 114.702 77.0625H108.547V75.0049H114.602V75.0132Z" fill="white"/>
                     <path d="M126.999 75.0132C128.727 75.0132 130.054 75.4998 130.97 76.477C131.886 77.4543 132.346 78.9346 132.346 80.9262C132.346 82.9055 131.886 84.3775 130.97 85.3383C130.054 86.3032 128.727 86.7856 126.999 86.7856H125.387C123.654 86.7856 122.332 86.3032 121.416 85.3383C120.496 84.3734 120.04 82.9013 120.04 80.9262C120.04 78.9387 120.5 77.4543 121.416 76.477C122.332 75.4998 123.658 75.0132 125.387 75.0132H126.999ZM125.387 77.0708C124.239 77.0708 123.406 77.3718 122.887 77.978C122.365 78.5841 122.108 79.5655 122.108 80.9262C122.108 82.2746 122.365 83.2477 122.879 83.8415C123.393 84.4352 124.23 84.7321 125.387 84.7321H126.999C128.135 84.7321 128.96 84.4352 129.482 83.8332C130 83.2353 130.261 82.2622 130.261 80.9097C130.261 79.5614 130.004 78.5841 129.49 77.978C128.976 77.3718 128.147 77.0708 126.999 77.0708H125.387Z" fill="white"/>
                     <path d="M141.166 75.0132C141.833 75.0132 142.405 75.108 142.882 75.3018C143.363 75.4915 143.756 75.7513 144.067 76.0688C144.378 76.3904 144.606 76.7657 144.751 77.1945C144.896 77.6233 144.971 78.0728 144.971 78.5387C144.971 78.9098 144.925 79.2809 144.83 79.6479C144.734 80.0149 144.589 80.3572 144.39 80.6788C144.191 81.0004 143.935 81.2808 143.62 81.5241C143.305 81.7674 142.919 81.9529 142.463 82.0807L145.215 86.7897H142.919L140.217 82.2374H137.129C136.93 82.2374 136.83 82.3364 136.83 82.5343V86.7897H134.762V82.1302C134.762 81.4663 134.907 80.9922 135.202 80.6994C135.492 80.4108 135.968 80.2623 136.623 80.2623H141.236C141.531 80.2623 141.779 80.217 141.991 80.1221C142.202 80.0314 142.372 79.9077 142.5 79.7551C142.629 79.6026 142.724 79.4294 142.791 79.2315C142.853 79.0335 142.886 78.8315 142.886 78.6212C142.886 78.2006 142.757 77.8377 142.492 77.5285C142.231 77.2234 141.812 77.0667 141.24 77.0667H134.75V75.0091H141.166V75.0132Z" fill="white"/>
                     <path d="M155.146 75.0132V77.0708H151.689V86.7897H149.604V77.0708H146.148V75.0132H155.146Z" fill="white"/>
                     <path d="M166.105 75.0132V77.0708H160.037C159.498 77.0708 159.108 77.2028 158.864 77.4625C158.619 77.7223 158.495 78.0604 158.495 78.4645C158.495 78.6377 158.52 78.8109 158.574 78.98C158.628 79.149 158.719 79.3016 158.856 79.4335C158.988 79.5655 159.166 79.6727 159.39 79.7469C159.61 79.8211 159.896 79.8624 160.248 79.8624H163.017C163.825 79.8624 164.484 79.9655 164.998 80.1675C165.512 80.3695 165.918 80.6335 166.217 80.9633C166.515 81.2891 166.718 81.6602 166.83 82.0808C166.942 82.5014 166.996 82.9178 166.996 83.3384C166.996 83.8373 166.925 84.2992 166.784 84.7156C166.644 85.1362 166.42 85.4991 166.117 85.8042C165.815 86.1135 165.425 86.3526 164.952 86.5299C164.48 86.7031 163.904 86.7897 163.224 86.7897H156.63V84.7321H163.295C163.858 84.7321 164.269 84.5961 164.53 84.3239C164.791 84.0518 164.923 83.6972 164.923 83.2683C164.923 83.0828 164.894 82.8972 164.836 82.7199C164.778 82.5385 164.679 82.3818 164.538 82.2498C164.397 82.1179 164.206 82.0065 163.966 81.9282C163.726 81.8457 163.427 81.8045 163.062 81.8045H160.31C159.515 81.8045 158.868 81.7055 158.362 81.5076C157.861 81.3097 157.463 81.0499 157.177 80.7242C156.891 80.3984 156.692 80.0314 156.58 79.6273C156.468 79.2191 156.414 78.815 156.414 78.4068C156.414 77.9202 156.481 77.4667 156.617 77.0543C156.75 76.642 156.97 76.2832 157.268 75.9822C157.567 75.6812 157.948 75.4462 158.416 75.2771C158.885 75.1081 159.444 75.0256 160.099 75.0256H166.105V75.0132Z" fill="white"/>
                     <path d="M159.253 59.8597C157.206 59.8597 155.196 59.0515 153.708 57.5382C149.808 53.658 145.878 49.646 142.078 45.7699C133.755 37.2757 125.146 28.4928 116.289 20.5181C112.215 16.8524 109.065 15.4216 105.044 15.4216C100.125 15.4216 95.4617 17.7925 92.5728 21.7593C90.0611 25.2065 85.2159 25.9734 81.7509 23.4746C78.2859 20.9758 77.515 16.1556 80.0267 12.7084C85.821 4.75018 95.1716 0 105.044 0C115.398 0 122.353 5.18314 126.684 9.07977C135.301 16.8359 143.532 25.1693 151.503 33.2966V9.31068C151.503 5.05119 154.972 1.59989 159.253 1.59989C163.535 1.59989 167.004 5.05119 167.004 9.31068V52.1489C167.004 55.2827 165.098 58.1072 162.18 59.2865C161.226 59.6741 160.236 59.8597 159.253 59.8597Z" fill="white"/>
                     <path d="M105.044 61.4595C91.7812 61.4595 83.1145 52.5942 76.1514 45.473C75.3141 44.6195 74.4893 43.7701 73.6604 42.9495L58.5569 27.9238V52.1488C58.5569 55.2662 56.6711 58.0783 53.7739 59.27C50.8809 60.4658 47.5443 59.8019 45.3311 57.6L15.4972 27.9238V52.1488C15.4972 56.4083 12.028 59.8596 7.74652 59.8596C3.465 59.8596 -0.00415039 56.4083 -0.00415039 52.1488V9.31066C-5.64754e-06 6.19335 1.88585 3.38118 4.78303 2.18951C7.6802 0.997847 11.0126 1.65759 13.23 3.8595L43.0597 33.5357V9.31066C43.0597 6.19335 44.9456 3.38118 47.8428 2.18951C50.7399 0.993723 54.0723 1.65759 56.2897 3.8595L84.6232 32.0472C85.5019 32.9213 86.3806 33.8161 87.2634 34.7233C93.2028 40.7971 98.3299 46.0421 105.044 46.0421C110.089 46.0421 114.822 43.5763 117.702 39.4446C120.144 35.9479 124.972 35.0779 128.487 37.5066C132.002 39.9353 132.876 44.7391 130.435 48.2357C124.657 56.5155 115.166 61.4595 105.044 61.4595Z" fill="white"/>
                   </g>
                   <defs>
                     <clipPath id="clip0">
                       <rect width="167" height="87" fill="white"/>
                     </clipPath>
                   </defs>
                 </svg>
               </b-col>
             </b-row>
           </b-container>
        </b-col>
        <b-col sm="12" md="10" lg="10" class="mt-2 mt-lg-3 mt-sm-3 mt-md-5 pr-0 pl-10 pl-4 ml-4 ml-sm-4 ml-md-0 pr-md-0">
          <b-container fluid class="px-0">
            <b-row>
              <b-col cols="12" lg="3" class="pl-lg-5 pl-md-2 pl-0">
                <b-button
                    squared
                    class="px-3 download-btn d-inline-flex border-1"
                    @click="ieDownload"
                >
                  <b-link
                      class="download-link"
                      href="#"
                      download
                  >
                    <h6 class="mb-0">회사소개서 다운로드</h6>
                  </b-link>
                </b-button>
                <div class="instagram-btn">
                  <b-link href="https://www.instagram.com/themon_sports/" target="_blank">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                         :width="[isMobile ? '40' : '40']"    :height="[isMobile ? '40' : '40']"
                         viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
                    <g>
                        <path class="st0" fill="#ffffff" d="M15,2.7c4,0,4.5,0,6.1,0.1c1.5,0.1,2.3,0.3,2.8,0.5c0.7,0.3,1.2,0.6,1.7,1.1s0.8,1,1.1,1.7
		c0.2,0.5,0.4,1.3,0.5,2.8c0.1,1.6,0.1,2.1,0.1,6.1s0,4.5-0.1,6.1c-0.1,1.5-0.3,2.3-0.5,2.8c-0.3,0.7-0.6,1.2-1.1,1.7
		s-1,0.8-1.7,1.1c-0.5,0.2-1.3,0.4-2.8,0.5c-1.6,0.1-2.1,0.1-6.1,0.1s-4.5,0-6.1-0.1c-1.5-0.1-2.3-0.3-2.8-0.5
		c-0.7-0.3-1.2-0.6-1.7-1.1c-0.5-0.5-0.8-1-1.1-1.7c-0.2-0.5-0.4-1.3-0.5-2.8C2.7,19.5,2.7,19,2.7,15s0-4.5,0.1-6.1
		c0.1-1.5,0.3-2.3,0.5-2.8C3.6,5.5,3.9,5,4.4,4.4c0.5-0.5,1-0.8,1.7-1.1c0.5-0.2,1.3-0.4,2.8-0.5C10.5,2.7,11,2.7,15,2.7 M15,0
		c-4.1,0-4.6,0-6.2,0.1C7.2,0.2,6.1,0.4,5.2,0.8c-1,0.4-1.8,0.9-2.7,1.7S1.2,4.2,0.8,5.2c-0.4,1-0.6,2-0.7,3.6C0,10.4,0,10.9,0,15
		s0,4.6,0.1,6.2c0.1,1.6,0.3,2.7,0.7,3.6c0.4,1,0.9,1.8,1.7,2.7s1.7,1.3,2.7,1.7c1,0.4,2,0.6,3.6,0.7C10.4,30,10.9,30,15,30
		s4.6,0,6.2-0.1c1.6-0.1,2.7-0.3,3.6-0.7c1-0.4,1.8-0.9,2.7-1.7s1.3-1.7,1.7-2.7c0.4-1,0.6-2,0.7-3.6C30,19.6,30,19.1,30,15
		s0-4.6-0.1-6.2c-0.1-1.6-0.3-2.7-0.7-3.6c-0.4-1-0.9-1.8-1.7-2.7s-1.7-1.3-2.7-1.7c-1-0.4-2-0.6-3.6-0.7C19.6,0,19.1,0,15,0z
		 M15,7.3c-4.3,0-7.7,3.4-7.7,7.7s3.4,7.7,7.7,7.7s7.7-3.4,7.7-7.7S19.3,7.3,15,7.3z M15,20c-2.8,0-5-2.2-5-5s2.2-5,5-5s5,2.2,5,5
		S17.8,20,15,20z M24.8,7c0,1-0.8,1.8-1.8,1.8c-1,0-1.8-0.8-1.8-1.8S22,5.2,23,5.2C24,5.2,24.8,6,24.8,7z"/>
                    </g>
                </svg>
                  </b-link>
                </div>
              </b-col>
              <b-col cols="12" lg="5" class="pr-0 pl-lg-4 pl-md-2 pl-0 mt-3 mt-md-4 mt-lg-0">
                <p class="font-weight-light text-white w-110 mb-2">
                  주식회사 더몬스포츠 <span>ㅣ</span> 대표이사 : 윤기영
                </p>
                <p class="text-white white no-wrap mb-2">서울특별시 강서구 양천로 30길 123-5 마곡 <br class="d-block d-sm-none">CJ빌딩 302호</p>
                <p class="text-white">사업자등록번호 : 123-87-01225</p>
              </b-col>
              <b-col sm="4" md="12" lg="4" class="pr-0 pl-10">
                <p class="font-weight-light text-white mb-2">
                  TEL. 070-7733-0804
                </p>
                <p class="text-white mb-2">
                  FAX. 02-3663-0804
                </p>
                <p class="text-white no-wrap">
                  MAIL. themonsports@themonsports.co.kr
                </p>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
    </b-row>
</template>

<script>
    // import IntersectionObserver from "./IntersectionObserver";

    export default {
        name: 'VFooter',
        components: {
            // IntersectionObserver
        },
        data() {
            return {
              isMobile: false,
            }
        },
      created() {
        if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
          this.isMobile = true;
        }
      },
      methods: {
        ieDownload() {
          if(this.is_ie) {
            if(confirm("다운로드 기능은 인터넷익스플로러(IE)브라우저를 지원하지 않습니다. " + "크롬 혹은 엣지브라우저에서 정상적으로 작동됩니다. 해당 브라우저로 이동하시겠습니까?")) {
              window.location = "microsoft-edge:" + window.location.href;
            }
          }
        },
      }
    }
</script>
