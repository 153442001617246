<template>
  <b-container fluid class="px-0 position-relative"
  >
    <b-container fluid class="px-0 position-absolute">
      <b-row class="pb-sm-0 pb-2">
        <b-col sm="12" class="h-100">
          <GlobalNavigationBar
          ></GlobalNavigationBar>
        </b-col>
      </b-row>
    </b-container>
    <b-row
        class="py-5 b-shadow bg-image"
        :style="{backgroundImage: 'url(' + $s3url + app.top_bg + ')'}"
    >
      <b-container class="py-5">
        <b-row class="py-sm-5">
          <b-col sm="12" class="py-sm-5 mt-4"
          >
            <b-container fluid class="px-sm-0 px-4">
              <b-row>
                <b-col cols="12" class="d-sm-none">
                  <img class="app-image w-25 mt-sm-1 mt-0" :src="$s3url + app.icon" alt="">
                </b-col>
                <b-col cols="12" class="pr-0 d-inline-flex">
                  <img class="app-image mt-sm-1 mt-0 d-none d-sm-inline-flex" :src="$s3url + app.icon" alt="">
                  <div class="app-title text-white ml-sm-3 ml-0 mt-sm-0 mt-3"
                  >
                    <h6 class="text-white font-weight-light mt-sm-1 mt-0 mb-2 ml-1  fs-mobile-16">
                      {{ app.subtitle }}
                    </h6>
                    {{ app.title }} APP
                    <h6 class="text-white font-weight-light fs-mobile-14 ml-1 lh-22 mt-4 pre-line">
                      {{ app.description }}
                    </h6>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
    <b-row class="bg-white py-5">
      <b-container class="py-sm-5">
        <b-row class="bg-white" align-h="around">
          <b-col sm="3" class="text-center px-sm-3 px-5">
            <h2 class="fs-30 text-dark mt-4 mb-4">Feature 1</h2>
            <h6 class="font-weight-light text-dark lh-24">
              {{ app.feature_1 }}
            </h6>
          </b-col>
          <b-col sm="3" class="text-center px-sm-3 px-5">
            <h2 class="fs-30 text-dark mt-4 mb-4">Feature 2</h2>
            <h6 class="font-weight-light text-dark lh-24">
              {{ app.feature_2 }}
            </h6>
          </b-col>
          <b-col sm="3" class="text-center px-sm-3 px-5">
            <h2 class="fs-30 text-dark mt-4 mb-4">Feature 3</h2>
            <h6 class="font-weight-light text-dark lh-24">
              {{ app.feature_3 }}
            </h6>
          </b-col>
        </b-row>
        <b-row class="mt-3 pt-5 px-sm-3 px-4">
          <b-col class="border-1 app-introduction p-sm-5 py-5">
            <h6 class="m-sm-5 mx-3 lh-24 fs-mobile-14" v-html="app.introduction"></h6>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
    <div class="bottom-wrapper">
      <img :src=" $s3url + app.bottom_bg" class="bottom-image" alt="">
      <b-container class="content-center">
        <b-row>
          <b-col class="px-sm-3 px-4">
            <h4 class="text-white font-weight-light lh-36 bottom-text fs-mobile-16 mb-0" v-html="app.bottom_text"></h4>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-row class="py-5 mb-5">
      <b-container class="my-sm-5">
        <b-row align-h="center">
          <b-col sm="12" class="mb-5">
            <h1 class="bottom-title font-brand text-center mb-sm-5">
              Get this application
            </h1>
          </b-col>
          <b-col cols="8" sm="3" class="text-center">
            <img class="w-50 icon-radius"
                 src="https://cyhcnk-studio-s3.s3.ap-northeast-2.amazonaws.com/themonstports-asset/app-icon1.png"
                 alt="">
            <b-button
                squared
                variant="transparent"
                class="app-btn border-4 mt-sm-5 mt-4"
                :href="app.appstore_link"
                target="_blank"
            >
              <h5 class="mb-0 font-brand">
                Go to APP STORE
              </h5>
            </b-button>
          </b-col>
          <b-col cols="8" sm="3" class="text-center mt-sm-0 mt-5">
            <img class="w-50 shadow icon-radius"
                 src="https://cyhcnk-studio-s3.s3.ap-northeast-2.amazonaws.com/themonstports-asset/app-icon2.png"
                 alt="">
            <b-button
                squared
                variant="transparent"
                class="app-btn border-4 mt-sm-5 mt-4"
                :href="app.playstore_link"
                target="_blank"
            >
              <h5 class="mb-0 font-brand">
                Go to PLAY STORE
              </h5>
            </b-button>
          </b-col>
          <b-col cols="8" sm="3" class="text-center mt-sm-0 mt-5">
            <img class="w-50"
                 src="https://cyhcnk-studio-s3.s3.ap-northeast-2.amazonaws.com/themonstports-asset/app-icon3.png"
                 alt="">
            <b-button
                squared
                variant="transparent"
                class="app-btn border-4 mt-sm-5 mt-4"
            >
              <h5 class="mb-0 font-brand">
                Download .apk file
              </h5>
            </b-button>
          </b-col>
          <b-col cols="8" sm="3" class="text-center mt-sm-0 mt-5">
            <img class="w-50 icon-radius"
                 src="https://cyhcnk-studio-s3.s3.ap-northeast-2.amazonaws.com/themonstports-asset/onestore.svg"
                 alt="">
            <b-button
                squared
                variant="transparent"
                class="app-btn border-4 mt-sm-5 mt-4"
                :href="app.onestore_link"
                target="_blank"
            >
              <h5 class="mb-0 font-brand">
                Go to ONE STORE
              </h5>
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
  </b-container>
</template>

<script>
import {EventBus} from "../../EventBus";
import GlobalNavigationBar from "../components/GlobalNavigationBar";

export default {
  name: "App",
  components: {
    GlobalNavigationBar
  },
  data() {
    return {
      app: {},
    }
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      let that = this;
      this.axios.get('/themon/application/' + this.$route.params.id).then(res => {
        that.app = res.data
      })
    }
  }
}
</script>
