<template>
    <b-container id="home" fluid class="px-0 bg-primary position-relative">
      <b-container fluid class="position-absolute">
        <b-row>
          <b-col sm="12" class="h-100">
            <GlobalNavigationBar
            ></GlobalNavigationBar>
          </b-col>
        </b-row>
      </b-container>
        <hooper
                :vertical="true"
                class="full-screen"
                :class="hooper_combination"
                :centerMode="true"
                :transition="500"
                :wheel-control="false"
                :touch-drag="true"
                ref="hooper"
                @slide="currentColor"
        >
            <slide
                    v-for="(slide, index) in slides"
                    :key="'slide_' + slide.id"
                    class=""
            >
                <b-container
                        fluid
                        class="w-100 vh-100 px-sm-5"
                >
                    <b-row class="h-100 px-sm-3 px-1" align-v="center">
                        <b-col sm="12" class="mt-sm-4" align-self="center">
                          <b-row class="home-top">
                            <b-col sm="12" class=" pr-0" align-self="end">
                              <vue-typed-js
                                  :strings="[slide.title_blink]"
                                  :loop="true"
                                  :typeSpeed="40"
                                  :contentType="'text'"
                                  :cursorChar="'_'"
                                  :smartBackspace="true"
                                  :backSpeed="20"
                                  :backDelay="3000"
                                  :autoInsertCss="true"
                              >
                                <h1 class="font-brand mon-title">
                                  {{slide.title}}
                                  <span class="font-brand typing"></span>
                                </h1>
                              </vue-typed-js>
                            </b-col>
                            <b-col sm="12" class="home-bottom">
                              <h6 class="mb-2 mon-subtitle"
                                  v-html="slide.subtitle"
                              >
                              </h6>
                            </b-col>
                          </b-row>
                          <b-row align-v="end">
                            <b-col>
                              <b-button
                                  squared
                                  :class="btn_color"
                                  variant="transparent"
                                  class="fs-20 font-brand mt-5 d-inline-flex border-4"
                                  @click="movePage(slide.to)"
                              >
                                {{slide.btnName}}
                              </b-button>
                              <div class="up-down mt-4">
                                <b-button
                                    @click.prevent="slideNext"
                                    class="d-inline-flex"
                                    :class="next_disabled"
                                    variant="transparent"
                                >
                                  <svg slot="hooper-next" :class="hooper_btn" width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="2" y="2" width="41" height="41" stroke="white" stroke-width="4"/>
                                    <path d="M12 19L23.2157 27L34 19" stroke="white" stroke-width="4"/>
                                  </svg>
                                </b-button>
                                <b-button
                                    @click.prevent="slidePrev"
                                    class="d-inline-flex ml-3"
                                    :class="prev_disabled"
                                    variant="transparent"
                                >
                                  <svg slot="hooper-prev" :class="hooper_btn" width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="2" y="2" width="41" height="41" stroke="white" stroke-width="4"/>
                                    <path d="M34 27L22.7843 19L12 27" stroke="white" stroke-width="4"/>
                                  </svg>
                                </b-button>
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                    </b-row>
                </b-container>
            </slide>
        </hooper>
    </b-container>
</template>

<script>
    import { Hooper, Slide, } from 'hooper';
    import 'hooper/dist/hooper.css';
    import {EventBus} from '../../EventBus';
    import GlobalNavigationBar from "../components/GlobalNavigationBar";

    export default {
        name: "Home",
        components: { Hooper, Slide, GlobalNavigationBar },
        data() {
            return {
                isMobile: false,
                hooper_combination: 'red-white',
                btn_color: 'white',
                hooper_btn: 'white',
                prev_disabled: false,
                next_disabled: false,
                slides: [
                    {
                        id: 0,
                        title: 'COMPLETE THE START AND\n',
                        title_blink: 'END OF A SPORTING EVENT',
                        subtitle: '스포츠 경기의 시작과 끝을 완성합니다.',
                        btnName: 'Jump to ABOUT US',
                        to: '/about-us'
                    },
                    {
                        id: 1,
                        title: 'EXPERIENCE,\n KNOW-HOW,\n',
                        title_blink : 'BEST PERFORMANCE',
                        subtitle: '수많은 경험과 데이터로부터 도출된 노하우를 통해 최고의 퍼포먼스를 제공합니다.',
                        btnName: 'Jump to Performance',
                        to: '/performance'
                    },
                    {
                        id: 2,
                        title: 'CREATE\n',
                        title_blink: 'NEW STANDARDS',
                        subtitle: 'SPORTS EVENTS 현장의 낙후된 운영 시스템을 <br class="d-block d-sm-none">개선하고 새로운 스탠다드를 만듭니다.',
                        btnName: 'Jump to Platform',
                        to: '/platform'
                    },
                    {
                        id: 3,
                        title: 'SUCCESSFUL\n',
                        title_blink: 'SPORTS EVENTS',
                        subtitle: '성공적인 SPORTS EVENTS를 위해 <br class="d-block d-sm-none">이제 우리와 함께 할 시간입니다.',
                        btnName: 'Open Inquiry form',
                        // to: '/?contact=1'
                        to: null
                    }
                ],
            }
        },
        created() {
          if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
              || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
            this.isMobile = true;
          }
        },
        mounted() {
        },
        methods: {
          movePage (url) {
            if(url !== null) {
              this.$router.push(url)
            } else {
              //   //메뉴 열고 컨택트 열기!
              EventBus.$emit('open-contact', true);
            }
          },
            slidePrev() {
                this.$refs.hooper.slidePrev();
            },
            slideNext() {
                this.$refs.hooper.slideNext();
            },
            currentColor() {
                let cs = this.$refs.hooper.currentSlide;
                if(cs === 0) {
                  EventBus.$on('svg-change', true);
                  this.$route.query.slide = "1"
                    this.hooper_combination = 'red-white';
                    EventBus.$emit('gnb_bg_class', 'white');
                    EventBus.$emit('hamburger_color', 'white');
                    this.btn_color = 'white';
                    this.hooper_btn = 'white';
                    this.prev_disabled = 'disabled';
                    this.next_disabled = false;
                } else if(cs === 1) {
                  EventBus.$on('svg-change', true);
                  this.$route.query.slide = "2"
                    this.hooper_combination = 'white-red';
                    EventBus.$emit('gnb_bg_class', 'red');
                    EventBus.$emit('hamburger_color', 'red');
                    this.btn_color = 'red';
                    this.hooper_btn = 'red';
                    this.prev_disabled = false;
                    this.next_disabled = false;
                } else if(cs === 2) {
                  this.$route.query.slide = "3"
                    this.hooper_combination = 'white-dark';
                    EventBus.$emit('gnb_bg_class', 'dark');
                    EventBus.$emit('hamburger_color', 'dark');
                    this.btn_color = 'dark';
                    this.hooper_btn = 'dark';
                    this.prev_disabled = false;
                    this.next_disabled = false;
                } else {
                  this.$route.query.slide = "4"
                    this.hooper_combination = 'dark-white';
                    EventBus.$emit('gnb_bg_class', 'white');
                    EventBus.$emit('hamburger_color', 'white');
                    this.btn_color = 'white-4';
                    this.hooper_btn = 'white';
                    this.prev_disabled = false;
                    this.next_disabled = 'disabled';
                }
            },
        }
    }
</script>
