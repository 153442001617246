import Vue from "vue";

let VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo, {
    container: "#contact",
    duration: 500,
    // easing: "ease",
    offset: 0,
    lazy: true,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
});
Vue.use(VueScrollTo, {
    container: '#mission',
    duration: 500,
    // easing: "ease",
    offset: 200,
    lazy: true,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
})