<template>
    <b-container fluid id="platform"
                 class="bg-contain position-relative px-0"
    >
      <b-container fluid class="px-sm-3 pl-2 pr-3 position-absolute">
        <b-row class="pb-sm-0 pb-2">
          <b-col sm="12" class="h-100 pr-sm-3 pl-0 pr-sm-3 pr-1">
            <GlobalNavigationBar
            ></GlobalNavigationBar>
          </b-col>
        </b-row>
      </b-container>
      <div class="position-fixed w-100 vh-100" style="background-image: url('https://cyhcnk-studio-s3.s3.ap-northeast-2.amazonaws.com/themonstports-asset/platform-bg.png');"></div>
      <b-container fluid class="px-sm-5 mx-sm-1 pt-5">
        <b-row class="px-sm-3 px-1 pt-5">
          <b-col sm="6" md="6" lg="5" class="pb-5 pr-sm-0 pr-3">
            <h1 class="text-white font-brand mb-0 platform-title mt-sm-5">
              PLATFORM
            </h1>
            <h5
                class="text-white font-weight-light mt-3 lh-30 fs-mobile-16"
            >
              자체 개발 통합운영관리 플랫폼 시스템을
              활용하여 기존 SPORTS EVENTS 현장의
              낙후된 운영 시스템을 개선하고 새로운
              스탠다드를 만듭니다.
            </h5>
            <b-row class="mt-4">
              <b-col class="mt-2">
                <h4 class="font-brand text-white mb-0">
                  Versions
                </h4>
                <b-container fluid>
                  <b-row>
                    <b-col cols="4" sm="6" md="4" lg="4" class="version-btns px-0"
                           v-for="version in versions"
                           :key="'version-button-' + version.id">
                      <b-button
                          pill
                          variant="transparent"
                          @click="current_version = version.title_en"
                          class="mt-5 text-white platform-btn d-inline-flex position-relative"
                          :class="{'selected' : current_version === version.title_en}"
                      >
                        <h6 class="fs-14 font-weight-light lh-18 content-center w-100">
                          <strong class="fs-18 font-brand">{{version.title_en}}</strong><br>
                          {{version.title}}
                        </h6>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-container>
                <b-col cols="12" class="w-100 pt-5 px-0 d-sm-none d-block">
                  <transition-group name="fade">
                    <div
                        v-for="version in versions"
                        :key="'version-desc-' + version.id"
                        v-if="is_expanded && current_version === version.title_en"
                        class="container-fluid"
                    >
                      <b-row>
                        <b-col cols="12" class="px-0">
                          <h6
                              class="text-white font-weight-light lh-30"
                          >
                            {{version.introduction}}
                          </h6>
                        </b-col>
                        <b-col class="px-0">
                          <b-button
                              v-if="version.items.length"
                              squared
                              variant="transparent"
                              class="border-1 border-white fs-20 mt-3 twelve-btn d-inline-flex py-2 px-5"
                              @click="is_expanded = false"
                          >
                            <h6 class="mb-0">{{version.items.length}}가지 특장점 보기</h6>
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                  </transition-group>
                </b-col>
                <b-button
                    squared
                    variant="transparent"
                    class="border-white border-1 fs-20 twelve-btn-back d-inline-flex mt-sm-5 py-2 mb-sm-0 mb-5"
                    @click="is_expanded = true"
                    v-if="!is_expanded"
                >
                  <h5 class="mb-0 px-5 font-weight-light">돌아가기</h5>
                </b-button>
                <!--                        다운로드 버튼 시작-->
                <b-row>
                  <b-col sm="12" class="mt-sm-5">
                    <div v-if="is_expanded">
                      <hr class="border-1 text-white mt-5 mb-4 text-left mx-0">
                      <h4 class="font-brand text-white mb-4">
                        Downloads
                      </h4>
                    </div>
                    <div
                        v-if="is_expanded"
                    >
                      <b-link
                          v-for="(app, index) in apps"
                          :key="'app_' + app.id"
                          @click="$router.push('/app/' + app.id)"
                      >
                        <b-row class="mb-3" align-h="start">
                          <b-col class="download-icon-col pr-0">
                            <div class="icon-wrapper">
                              <img class="" :src="$s3url + app.icon" alt="">
                            </div>
                          </b-col>
                          <b-col class="mt-1 pl-0 pr-0" align-self="center">
                            <h4 class="text-white font-weight-light mb-0 fs-mobile-20">
                              {{app.title}} APP
                            </h4>
                            <h6 class="fs-12 font-weight-light text-white mt-1">설치/다운로드</h6>
                          </b-col>
                        </b-row>
                      </b-link>
                    </div>
                  </b-col>
                </b-row>
                <!--                        다운로드 버튼 끝-->
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" sm="12" md="1" offset-sm="3" offset-md="1" offset-lg="0" class="text-center center-arrow d-none d-md-block" align-self="center">
            <transition name="right_fade">
              <img
                  v-if="is_expanded"
                  src="https://cyhcnk-studio-s3.s3.ap-northeast-2.amazonaws.com/themonstports-asset/arrow.png"
                  alt=""
              >
            </transition>
          </b-col>
          <!--                    version 텍스트 with 트랜지션 시작-->
          <b-col cols="12" sm="7" md="7" offset-sm="2" offset-md="2" offset-lg="1" class="w-100 px-3 px-sm-0 d-sm-block d-none platform-introduction platform-scroll-hide">
            <transition-group name="fade">
              <div
                  v-for="version in versions"
                  :key="'version-desc-' + version.id"
                  v-if="is_expanded && current_version === version.title_en"
                  class="container-fluid"
              >
                <b-row align-v="center">
                  <b-col sm="7" class=" px-0">
                    <h5
                        v-html="version.introduction"
                        class="text-white font-weight-light lh-36"
                    >
                    </h5>
                    <b-button
                        v-if="version.items.length"
                        squared
                        variant="transparent"
                        class="border-1 border-white fs-20 mt-3 twelve-btn d-inline-flex py-2 px-3"
                        @click="is_expanded = false"
                    >
                      <h5 class="mb-0 px-4 fs-19">{{version.items.length}}가지 특장점 보기</h5>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </transition-group>
          </b-col>
          <b-container
              v-for="version in versions"
              :key="'version-features-' + version.id"
              class="px-0 platform-container"
              :class="{'shirink': !is_expanded}"
              v-if="current_version === version.title_en && !is_expanded"
          >
            <b-row class="row-grid-1" align-v="stretch">
              <b-col
                  md="8"
                  lg="5"
                  class="row-grid-1-item w-100 br-30 card p-4 m-2"
                  v-for="(item, index) in version.items"
                  :key="'feature-item-' + version.title_en + '-' + index"
              >
                <b-container fluid>
                  <b-row>
                    <b-col cols="3" v-if="item.icon_img" class="">
                      <div class="item-img-container">
                        <img :src="$s3url + item.icon_img" alt="">
                      </div>
                    </b-col>
                    <b-col cols="9" class="px-0 pt-2" align-self="center">
                      <h4 class="">{{item.title}}</h4>
                    </b-col>
                  </b-row>
                </b-container>
                <h6 class="lh-22 mt-3 fs-mobile-14">{{item.content}}</h6>
              </b-col>
            </b-row>
          </b-container>
        </b-row>
      </b-container>
    </b-container>
</template>

<script>
    import {EventBus} from "../../EventBus";
    import GlobalNavigationBar from "../components/GlobalNavigationBar";

    export default {
        name: 'Platform',
      components: {
        GlobalNavigationBar
      },
        data() {
            return {
                isMobile: false,
                current_version: null,
                is_expanded: true,

                versions: [],
                apps: [],

                downloads: [
                    {
                        id: 0,
                        icon: 'https://cyhcnk-studio-s3.s3.ap-northeast-2.amazonaws.com/themonstports-asset/download-icon1.svg',
                        title: '더몬스포츠 APP',
                        to: '/app',
                    },
                    {
                        id: 1,
                        icon: 'https://cyhcnk-studio-s3.s3.ap-northeast-2.amazonaws.com/themonstports-asset/download-icon2.svg',
                        title: 'TEAM79 APP',
                        to: '/platform/apps/team79',
                    },
                    {
                        id: 2,
                        icon: 'https://cyhcnk-studio-s3.s3.ap-northeast-2.amazonaws.com/themonstports-asset/download-icon3.svg',
                        title: 'V리그 출입시스템 APP',
                        to: '/platform/apps/v-league',
                    },
                ],
            }
        },
        watch:{
            'current_version': function (val) {
                this.is_expanded = true;
            }
        },
        created() {
            this.fetch();

            const myLocation = this.$route.path
            if(myLocation === '/platform') {
                EventBus.$emit('gnb_bg_class', 'white');
                EventBus.$emit('hamburger_color', 'white');
            }

          if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
              || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
            this.isMobile = true;
          }

        },
      methods: {
            fetch(){
                let that = this;
                that.axios.get('/themon/platforms').then(res=>{
                    that.versions = res.data
                    that.current_version = res.data[0].title_en
                })
                that.axios.get('/themon/applications').then(res=>{
                    that.apps = res.data
                })
            },
        }
    }
</script>
