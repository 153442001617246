<template>
  <b-container fluid id="about" class="px-0 position-relative">
    <b-container fluid class="px-0 position-absolute">
      <b-row class="">
        <b-col sm="12" class="pr-sm-3 pr-4 pl-sm-3">
          <GlobalNavigationBar
          ></GlobalNavigationBar>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="position-relative">
      <b-row class="">
        <b-col lg="10" class="mt-5 pt-5 d-block d-sm-none">
          <transition name="about">
            <h1 class="font-brand about-title text-uppercase" v-if="aboutText">
              We make the beginning <br>and the end of sports
              <span class="font-brand typing"></span>
            </h1>
          </transition>
          <vue-typed-js
              id="about-typed-js2"
              :strings="['스포츠 경기의 보이지 않는 플레이어인 THE MON SPORTS 경기 운영의 새로운 스탠다드를 만듭니다.<br><br>THE MON SPORTS는 체계적인 시스템을 통해 스포츠 산업에 새로운 스탠다드를 제시합니다.']"
              :typeSpeed="20"
              :contentType="'html'"
              :cursorChar="'_'"
              :autoInsertCss="true"
          >
            <p class="mt-4 mb-0 fs-mobile-15">
              <span class="typing"></span>
            </p>
          </vue-typed-js>
        </b-col>
        <b-col sm="12" class="about-bg">
          <img class="bg-image w-100" src="https://cyhcnk-studio-s3.s3.ap-northeast-2.amazonaws.com/themonstports-asset/transparent2.png" alt="">
        </b-col>
      </b-row>
      <b-row align-v="center">
        <b-col lg="10" class="pt-sm-5 pt-md-0 pt-5 content-center d-none d-sm-block">
          <b-container fluid="">
            <b-row>
              <b-col xl="10" lg="9" md="8" sm="8" class="pl-4 pl-sm-5 pl-md-5 pr-lg-5 pl-xl-0">
                <transition name="about">
                  <h1 class="font-brand about-title text-uppercase" v-if="aboutText">
                    We make the beginning <br>and the end of sports
                    <span class="font-brand typing"></span>
                  </h1>
                </transition>
                <vue-typed-js
                    id="about-typed-js"
                    :strings="['스포츠 경기의 보이지 않는 플레이어인 THE MON SPORTS 경기 운영의 새로운 스탠다드를 만듭니다.<br><br>THE MON SPORTS는 체계적인 시스템을 통해 스포츠 산업에 새로운 스탠다드를 제시합니다.']"
                    :typeSpeed="20"
                    :contentType="'html'"
                    :cursorChar="'_'"
                    :autoInsertCss="true"
                >
                  <p class="mt-4 mb-0 fs-mobile-15">
                    <span class="typing"></span>
                  </p>
                </vue-typed-js>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </b-container>
    <!--        인트로 끝-->
    <!--        mission 시작-->
    <b-row id="mission" class="bg-dark bg-image py-5"
           style="background: url('https://cyhcnk-studio-s3.s3.ap-northeast-2.amazonaws.com/themonstports-asset/about-mission-bg.png')"
    >
      <b-container>
        <b-row>
          <b-col sm="12" class="">
            <h3 class="font-brand text-white ml-sm-4 ml-3 pl-sm-2 py-3">MISSION</h3>
          </b-col>
        </b-row>
      </b-container>
      <b-col sm="12" class="pb-4">
        <b-row align-h="center" class="ml-sm-3">
          <!--                    모바일 로고-->
          <b-col class="d-block d-sm-none mt-4 pl-4 pr-4 ml-1">
            <img
                class="w-100"
                src="https://cyhcnk-studio-s3.s3.ap-northeast-2.amazonaws.com/themonstports-asset/mission-logo.svg"
                alt=""
            >
          </b-col>
          <!--                    모바일로고 끝-->
          <b-col sm="3" class="mt-sm-5 pt-3 pt-sm-4 pl-0">
            <h3 class="font-brand text-white manual-text-align mt-3 mt-sm-4">
              Manual
            </h3>
            <h6 class="text-white manual-text-align">
              스포츠 경기 운영에 필요한 기준 매뉴얼과<br>
              교육 프로그램을 개발하고<br v-if="is_ie">
              효과적인 적용법을<br v-if="!is_ie"> 연구합니다.
            </h6>
          </b-col>
          <b-col sm="4" class="text-center mt-4 d-none d-sm-block pl-0 pr-4" align-self="end">
            <img class="w-100" src="https://cyhcnk-studio-s3.s3.ap-northeast-2.amazonaws.com/themonstports-asset/mission-logo.svg" alt="">
          </b-col>
          <b-col sm="3" class="px-0 mt-sm-3 my-5">
            <h3 class="font-brand text-white operation-text-align">Operation</h3>
            <h6 class="text-white operation-text-align">안전하고 질서 정연한 스포츠 경기
              운영 방식이<br> 자리잡을 수 있도록
              선진적인 문화를 만드는 데 <br class="d-block d-sm-none">일조합니다.</h6>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-sm-5">
            <h3 class="font-brand text-white text-center">
              Network
            </h3>
            <h6 class="text-white text-center">
              모든 시나리오에 대해 효과적으로<br class="d-block d-sm-none">
              대처할 수 있도록 <br class="d-none d-sm-block">실시간 커뮤니케이션과<br class="d-block d-sm-none">
              네트워킹 시스템을 개발 및 발전시킵니다.
            </h6>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!--        mission 끝-->
    <!--        work scope 시작-->
    <b-container fluid class="px-sm-3 px-0">
      <b-container class="px-0">
        <b-row class="py-5 my-sm-5 h-100" align-h="center">
          <b-col sm="12" class="mt-sm-5 pt-sm-4">
            <h3 class="font-brand ml-sm-5 ml-3 mt-sm-0 mt-3">WORK SCOPE</h3>
          </b-col>
          <b-col
              cols="12" xl="8"
              class="px-sm-5 px-0 mt-sm-5 pt-2"
              data-aos="fade-right"
              data-aos-easing="ease"
          >
            <b-container fluid class="pr-sm-3 pr-0 pl-sm-3 pl-4 ml-sm-0 ml-1">
              <b-row class="h-100">
                <b-col cols="4" sm="5" class="pr-0">
                  <img class="w-100 image-resize-top-left right-img" src="https://cyhcnk-studio-s3.s3.ap-northeast-2.amazonaws.com/themonstports-asset/work+scope+(2).jpg" alt="">
                </b-col>
                <b-col cols="8" sm="7" class="px-0">
                  <b-container>
                    <b-row class="h-100">
                      <b-col class="pl-3 pr-sm-3 pr-5">
                        <img class="w-100 right-resize" src="https://cyhcnk-studio-s3.s3.ap-northeast-2.amazonaws.com/themonstports-asset/work+scope+(3).jpg" alt="">
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
              <b-row class="mt-xl-5 pt-xl-5 h-100">
                <b-col cols="8" class="pr-0">
                  <img class="w-100 right-img left-resize-bottom second-top-border" src="https://cyhcnk-studio-s3.s3.ap-northeast-2.amazonaws.com/themonstports-asset/work+scope+(5)+1.png" alt="">
                </b-col>
                <b-col cols="4" class="pl-0 pr-sm-3 pr-5">
                  <img class="w-100 right-resize-bottom second-top-border" src="https://cyhcnk-studio-s3.s3.ap-northeast-2.amazonaws.com/themonstports-asset/work+scope+(4).jpg" alt="">
                </b-col>
              </b-row>
            </b-container>
          </b-col>
          <b-col xl="4" class="px-xl-0">
            <b-row class="" align-v="stretch">
              <b-col
                  md="12" lg="6" xl="12"
                  class="mt-xl-3 card right-card pt-xl-3 pt-lg-0 pb-xl-5 pb-sm-4 pb-3 bg-transparent ml-0 ml-sm-2 ml-md-4 ml-lg-0 pl-sm-4 pl-lg-5 pl-xl-3"
                  data-aos="fade-down"
                  data-aos-delay="100"
              >
                <b-container fluid class="pl-lg-3 pl-sm-3 pl-0">
                  <b-row>
                    <b-col class="mt-xl-3">
                      <h5 class="mt-xl-2 workscope-title">
                        스포츠 마케팅 기획
                      </h5>
                      <h6 class="font-weight-light about-card-description mt-1 fs-14 lh-18">
                        고객 브랜드 컨셉에 맞는 스포츠 이벤트 기획<br>
                        스포츠를 활용한 VIP HOSPITALITY PROGRAM<br>
                        기획 SOPORTS SOPONSORSHIP
                      </h6>
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
              <b-col
                  md="12" lg="6" xl="12"
                  class=" card right-card pt-lg-0 pt-md-3 pb-xl-5 pb-md-4 pb-3 bg-transparent ml-0 ml-sm-3 ml-md-4 ml-lg-0"
                  data-aos="fade-down"
                  data-aos-delay="150"
              >
                <b-container fluid class="pl-md-4 pl-lg-3 pl-sm-3 pl-0">
                  <b-row>
                    <b-col class="">
                      <h5 class="mt-3 mt-sm-2 mt-md-0 mt-xl-3 workscope-title">
                        프로 스포츠 운영
                      </h5>
                      <h6 class="about-card-description mt-1 fs-14 lh-18">
                       PGA, LPGA, KPGA, KLPGA 정규투어 운영<br>
                        국내 스포츠 연맹, 협회 주관 프로스포츠 리그 운영
                      </h6>
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
              <b-col
                  md="12" lg="6" xl="12"
                  class="card right-card pb-sm-4 pb-md-4 pb-lg-5 pb-3 bg-transparent ml-0 ml-sm-2 ml-md-4 ml-lg-0 pl-sm-4 pl-lg-5 pl-xl-3"
                  data-aos="fade-down"
                  data-aos-delay="200"
              >
                <b-container fluid class="pl-lg-3 pl-sm-3 pl-0">
                  <b-row>
                    <b-col class="mt-3">
                      <h5 class=" workscope-title">
                        스포츠 이벤트 컨설팅
                      </h5>
                      <h6 class="about-card-description mt-1 fs-14 lh-18">
                        스포츠 이벤트 STAFF 모집-교육-운영 컨설팅<br>
                        VOLUNTEER 프로그램 기획 및 운영 컨설팅<br>
                        스포츠 이벤트 운영 전문가 양성<br>
                        보안 & 경호 전문 컨설팅 및 운영
                      </h6>
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
              <b-col
                  md="12" lg="6" xl="12"
                  class="mt-sm-2 mt-3 card right-card pb-5 bg-transparent ml-0 ml-sm-3 ml-md-4 ml-lg-0"
                  data-aos="fade-down"
                  data-aos-delay="250"
              >
                <b-container fluid class="pl-md-4 pl-lg-3 pl-sm-3 pl-0">
                  <b-row>
                    <b-col class="mt-md-2 mt-lg-0">
                      <h5 class="workscope-title">
                        스포츠 플랫폼 서비스
                      </h5>
                      <h6 class="font-weight-light about-card-description mt-1 fs-14 lh-18">
                        모바일 APP 기반 통합 운영관리 시스템 개발 <br class="d-block d-sm-none">스포츠 이벤트<br class="d-none d-sm-block">
                        관계자-관람객 전용 플랫폼 제공 <br class="d-block d-sm-none">기업 주관 아마추어 스포츠<br class="d-none d-sm-block">
                        이벤트 전용 플랫폼 제공
                      </h6>
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
    <!--        work scope 끝-->
    <!--        ci 시작-->
    <b-container fluid class="px-sm-3 px-0">
      <b-container class="px-0">
        <b-row align-h="center" class="py-sm-5 my-sm-5">
          <b-col sm="12" class="my-sm-0 mt-5">
            <h3 class="font-brand ml-sm-5 ml-3 mt-sm-5 mb-0">C.I</h3>
          </b-col>
          <b-col sm="9" offset-sm="1" class="pb-3 mt-sm-4 pt-sm-4 mb-sm-5 ml-sm-0 px-0">
            <b-container fluid>
              <b-row align-h="center">
                <b-col cols="10" sm="8" offset-sm="2" class="text-center px-sm-3 px-5 pt-sm-0 pt-4">
                  <img class="w-100" src="https://cyhcnk-studio-s3.s3.ap-northeast-2.amazonaws.com/themonstports-asset/ci-logo.gif" alt="">
<!--                  <GnbLogoBlack></GnbLogoBlack>-->
<!--                  <img src="https://cyhcnk-studio-s3.s3.ap-northeast-2.amazonaws.com/themonstports-asset/clilogo.svg" alt="">-->
                </b-col>
              </b-row>
            </b-container>
            <b-row align-h="center" class="pt-sm-5 mt-5"
                   :class="{'mr-sm-5': is_ie}"
            >
              <b-col sm="10" offset-sm="4" class="px-0">
                <h6 class="font-weight-light px-sm-0 px-5 fs-mobile-15"
                    :class="[{'ml-sm-4': !is_ie}, {'mr-sm-0': is_ie}]"
                >
                  <strong class="font-brand">A.</strong> 심볼의 이어지는 획은 스포츠 경기의 시작과 끝을 함께하는
                  본사의 전문 서비스를 상징
                </h6>
                <h6 class="font-weight-light mt-4 fs-mobile-15 px-sm-0 px-5"
                    :class="[{'ml-sm-4': !is_ie}, {'mr-sm-0': is_ie}]"
                >
                  <strong class="font-brand">B.</strong> 심볼의 둥근 곡선은 다양하고 역동적인 스포츠 경기들을 상징
                </h6>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="8" sm="3" class="text-center d-sm-block d-none">
            <b-button
                squared
                variant="transparent"
                class="border-4 ci-btn mt-sm-5 mt-3 d-inline-flex"
                @click="ieDownload"
            >
              <b-link
                  class="download-link d-inline-flex"
                  href="https://cyhcnk-studio-s3.s3.ap-northeast-2.amazonaws.com/themonstports-asset/TheMonSports_CI.eps"
                  download
              >
                <h6 class="font-brand ml-sm-4 mb-0 fs-14">THE MON SPORT CI DOWNLOAD (.eps)</h6>
              </b-link>
            </b-button>
          </b-col>
          <b-col cols="12" class="text-center d-sm-none d-block px-0">
            <b-button
                squared
                variant="transparent"
                class="border-4 mt-sm-5 mt-3 d-inline-flex"
                @click="ieDownload"
            >
              <b-link
                  class="download-link d-inline-flex"
                  href="https://cyhcnk-studio-s3.s3.ap-northeast-2.amazonaws.com/themonstports-asset/TheMonSports_CI.eps"
                  download
              >
                <h6 class="font-brand mb-0 fs-14 text-dark">THE MON SPORT CI DOWNLOAD (.eps)</h6>
              </b-link>
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
    <b-container fluid class="position-relative pb-sm-0 pb-5 px-sm-3 px-0">
      <b-container class="px-0 py-5 my-5">
        <b-row align-h="center" class="mb-5 pb-sm-4">
          <b-col sm="12">
            <h3 class="font-brand ml-sm-5 ml-3 mb-0">LOGO DETAIL</h3>
          </b-col>
          <b-col>
            <h6 class="font-brand ld-sub">We make the beginning and the end of Sports</h6>
          </b-col>
        </b-row>
        <b-container fluid class="pt-sm-4 mt-sm-1 ml-sm-5">
          <b-row class="mt-5 ml-sm-5">
            <b-col cols="12" sm="12" align-self="center">
              <img class="w-100" src="https://cyhcnk-studio-s3.s3.ap-northeast-2.amazonaws.com/themonstports-asset/full.svg" alt="">
            </b-col>
          </b-row>
        </b-container>
        <b-row class="ml-sm-3 pt-5 mt-sm-0 mr-sm-0 mr-4 about-justify-content-end">
          <b-col
              cols="1"
              sm="6"
              md="6"
              lg="1"
              v-for="color in colors"
              :key="'color_' + color.id"
              class="pt-sm-5 pt-4 px-0 mx-sm-3 mx-5"
          >
            <div class="ci-color ml-4" :style="[{backgroundColor: color.hex}, {border: color.border}]"></div>
            <h6 class="font-brand white-space-pre-line mt-sm-4 mt-2 text-center fs-14 mr-sm-3">
              {{color.name}}
            </h6>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
    <!--        ci 끝-->
    <!--        brand story 시작-->
    <b-container fluid class="px-sm-3 px-0">
      <b-container class="px-0">
        <b-row class="py-5 my-5" align-h="between">
          <b-col sm="12" class="my-5">
            <h3 class="font-brand ml-sm-5 ml-3">BRAND STORY</h3>
          </b-col>
          <b-col sm="6" class="px-0 ml-sm-5">
            <img class="w-100 ml-sm-3"
                 src="https://cyhcnk-studio-s3.s3.ap-northeast-2.amazonaws.com/themonstports-asset/brandstory-img.png"
                 alt=""
                 data-aos="fade-right"
                 data-aos-easing="ease"
            >
          </b-col>
          <b-col sm="5" align-self="end" class="mt-sm-0 mt-5 mt-lg-0 mt-md-0 ml-sm-0 ml-3 px-sm-0 px-3">
            <h6 class="font-weight-light mt-sm-0  fs-14 lh-18"
                data-aos="fade-down"
            >
              스포츠 경기의 주인공은 단연 선수들입니다.
            </h6>
            <h6 class="font-weight-light mt-4 mt-xl-4 fs-14 text-uppercase lh-18"
                data-aos="fade-down"
                data-aos-delay="100"
            >
              그러나 그 뒤에는 선수와 관객이 스포츠 경기에 <br class="d-block d-sm-none">온전히 몰입할 수 있도록<br class="d-none d-sm-block">
              도움을 주는 <br class="d-block d-sm-none">Sports Events 전문가의 역할이 있습니다.
            </h6>
            <h6 class="font-weight-light mt-4 mt-xl-4 fs-14 lh-18"
                data-aos="fade-down"
                data-aos-delay="150"
            >
              선수와 관중을 보호하고 편의를 제공하며 <br class="d-block d-sm-none">예상치 못한 시나리오에<br class="d-none d-sm-block">
              대응할 수 있도록 <br class="d-block d-sm-none">한시도 긴장을 늦추지 않습니다.
            </h6>
            <h6 class="font-weight-light mt-4 fs-14 lh-18"
                data-aos="fade-down"
                data-aos-delay="200"
            >
              스포츠맨십에 따라 경기를 이끄는 심판이 존재하듯,<br>
              경기장 안팎의 보이지 않는 곳에서 이해와 원칙을<br class="d-block d-sm-none"> 바탕으로<br class="d-none d-sm-block">
              체계적인 시스템과 노하우, 리더십을 발휘 <br class="d-block d-sm-none">할 수 있는<br class="d-none d-sm-block">
              현장 운영 전문가의 역할이 필요합니다.
            </h6>
            <h6 class="font-weight-light mt-4 mb-0 fs-14 text-uppercase lh-18"
                data-aos="fade-down"
                data-aos-delay="250"
            >
              새로운 시대 성공적인 Sports Events의 경기 운영 <br class="d-block d-sm-none">비즈니스의<br class="d-none d-sm-block">
              No.1 리딩 기업 THE MON SPORTS
              <br class="d-block d-sm-none"> 신뢰하셔도 좋습니다.
            </h6>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
    <!--        brand story 끝-->
    <!--        apps 시작-->
    <b-row class="">
      <b-container>
        <b-row>
          <b-col sm="12" class="">
            <h3 class="font-brand ml-sm-4 ml-3 pl-sm-2 pt-5 mt-sm-5">APPS</h3>
          </b-col>
        </b-row>
      </b-container>
      <b-container class="pb-5 mb-sm-5" :class="{'ie-mb': is_ie}">
        <b-row
            v-for="app in apps"
            :key="'app_' + app.id"
            class="my-5 pt-sm-5 pb-sm-0 pb-5"
            align-h="center"
        >
          <b-col
              cols="8" sm="3"
              offset-sm="2"
              data-aos="fade-right"
              data-aos-easing="ease"
          >
            <div class="app-image-wrapper">
              <img class="w-100" :src="$s3url + app.image" alt="">
            </div>
          </b-col>
          <b-col
              sm="6" offset-sm="1" align-self="center"
              offset="1"
              class="pl-sm-4 mt-sm-0 mt-5 px-sm-3 px-4"
              data-aos="fade-down"
              data-aos-delay="100"
          >
            <h4 class="font-weight-bold">
              {{app.title}} APP
            </h4>
            <b-button
                squared
                variant="transparent"
                class="border-4 about-btn mt-2 mb-4 d-inline-flex"
                @click="$router.push('/app/' + app.id)"
            >
              <h6 class="fs-16 font-weight-bold font-brand mb-0">
                GO DOWNLOAD
              </h6>
            </b-button>
            <h6 class="font-weight-light white-space-pre-line fs-14 lh-22 pr-sm-0 pr-3">
              {{app.description}}
            </h6>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
  </b-container>
</template>

<script>
import GlobalNavigationBar from "../components/GlobalNavigationBar";
import GnbLogoBlack from "../components/GnbLogoBlack";
import {EventBus} from "../../EventBus";

export default {
  name: "About",
  components: {
    GlobalNavigationBar, GnbLogoBlack

  },
  created() {
    this.fetch();

    const myLocation = this.$route.path
    if(myLocation === '/about-us') {
      EventBus.$emit('gnb_bg_class', 'dark');
      EventBus.$emit('hamburger_color', 'black');
    }
    let ua = navigator.userAgent;
    let is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
    if(is_ie) {
      this.is_ie = true;
    }
  },
  mounted() {
    this.aboutText = true;

    setTimeout(() => {
      this.toMission();
    },5000);
  },
  data() {
    return {
      aboutText: false,
      is_ie: false,
      colors: [
        {
          id: 1,
          hex: '#b61f24',
          name: '#B61F24'
        },
        {
          id: 2,
          hex: '#2F2F2F',
          name: '#2F2F2F'
        },
        {
          id: 3,
          hex: '#C4C4C4',
          name: '#C4C4C4'
        },
        {
          id: 4,
          hex: '#FFFFFF',
          border: 'solid 1px #c4c4c4',
          name: '#FFFFFF'
        }
      ],
      apps: [],
    }
  },
  methods: {
    ieDownload() {
      if(this.is_ie) {
        if(confirm("다운로드 기능은 인터넷익스플로러(IE)브라우저를 지원하지 않습니다. " + "크롬 혹은 엣지브라우저에서 정상적으로 작동됩니다. 해당 브라우저로 이동하시겠습니까?")) {
          window.location = "microsoft-edge:" + window.location.href;
        }
      }
    },
    fetch(){
      let that = this;
      that.axios.get('/themon/applications').then(res=>{
        that.apps = res.data
      })
    },
    toMission(){
      const options = {
        container: 'body'
      }
      this.$scrollTo(document.getElementById("mission"), options)
    },
  }
}
</script>
