import 'core-js'
import '@babel/polyfill'
import 'regenerator-runtime/runtime'
import 'intersection-observer'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'

Vue.config.productionTip = false

import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios); // AXIOS
axios.defaults.baseURL = 'https://admin.cyhcnk.com/api/';
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/';
Vue.prototype.$s3url = 'https://cyhcnk-studio-s3.s3.ap-northeast-2.amazonaws.com/'

import VueTypedJs from 'vue-typed-js';
Vue.use(VueTypedJs); // TypedJS

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
Vue.use(BootstrapVue); // 부트스트랩
Vue.use(BootstrapVueIcons); // 부트스트랩 아이콘

window.$ = require('jquery')
import AOS from 'aos'
import 'aos/dist/aos.css'

Vue.component('main-vue', require('./App').default); // 최상단 뷰파일
import router from './router';
Vue.use(VueRouter);

import './style/app.scss'

import './scrollTo'

new Vue({
  created () {
    AOS.init();
  },
  render: h => h(App),
  router
}).$mount('#app');