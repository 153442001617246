<template>
  <b-navbar
      class="px-3 pt-0 w-100" id="gnb"
      :class="{'fixed-top': currentPage === '/'}"
  >
    <b-container fluid class="pt-sm-3 pt-0 px-sm-5 z-1036" :class="{'disappear': sidebar}">
      <b-navbar-brand
          @click="$router.push('/')"
          :class="[{'ml-2' : currentPage === '/platform'},
          {'ml-sm-0': currentPage === '/platform'},
          {'pl-0' : currentPage === '/about-us'},
          {'pl-sm-0': currentPage === '/about-us'}]"
      >
        <svg class="cursor-pointer" :class="[ logo_color, {'red-forced' : sidebar}, {'dark': currentPage === '/about-us'}]"
             width="114" height="43" viewBox="0 0 114 43" xmlns="http://www.w3.org/2000/svg">
          <path d="M108.709 41.0716C107.312 41.0716 105.94 40.517 104.924 39.4787C102.262 36.8164 99.5796 34.0636 96.9852 31.4042C91.3042 25.576 85.4279 19.5498 79.3819 14.0781C76.6008 11.563 74.4506 10.5812 71.7063 10.5812C68.348 10.5812 65.1652 12.208 63.1932 14.9297C61.4787 17.2949 58.1714 17.8212 55.8062 16.1067C53.4409 14.3922 52.9147 11.0848 54.6292 8.71961C58.5844 3.25924 64.9671 0 71.7063 0C78.7736 0 83.5211 3.55631 86.4776 6.22991C92.3595 11.5516 97.9783 17.2695 103.419 22.8458V6.38835C103.419 3.46578 105.787 1.09773 108.709 1.09773C111.632 1.09773 114 3.46578 114 6.38835V35.781C114 37.9312 112.699 39.8692 110.707 40.6783C110.056 40.9443 109.38 41.0716 108.709 41.0716Z"/>
          <path d="M71.7062 42.1693C62.6528 42.1693 56.7369 36.0865 51.9838 31.2005C51.4123 30.6149 50.8493 30.032 50.2835 29.469L39.9738 19.1594V35.781C39.9738 37.9199 38.6866 39.8494 36.7089 40.667C34.7342 41.4875 32.4566 41.032 30.9459 39.5212L10.5812 19.1594V35.781C10.5812 38.7036 8.21318 41.0716 5.29061 41.0716C2.36805 41.0716 0 38.7036 0 35.781V6.38838C0.00282921 4.2495 1.29012 2.31998 3.26773 1.50234C5.24535 0.684698 7.52003 1.13737 9.03365 2.64817L29.3954 23.01V6.38838C29.3954 4.2495 30.6827 2.31998 32.6603 1.50234C34.638 0.681868 36.9126 1.13737 38.4263 2.64817L57.7667 21.9886C58.3665 22.5884 58.9663 23.2023 59.5689 23.8248C63.6232 27.9922 67.1229 31.5909 71.7062 31.5909C75.1494 31.5909 78.3803 29.8991 80.3466 27.0642C82.013 24.665 85.309 24.0681 87.7082 25.7345C90.1074 27.4009 90.7043 30.6969 89.0379 33.0961C85.094 38.7771 78.6151 42.1693 71.7062 42.1693Z"/>
        </svg>
      </b-navbar-brand>
      <b-navbar-nav>
        <div
            class="hamburger mt-md-4 pt-md-2"
            :class="[{'mr-4, mr-sm-0': currentPage === '/platform'}, {'mr-4, mr-sm-0': currentPage !== '/platform'},{'mr-2': currentPage === '/'}
            ]"

            v-b-toggle.sidebar
        >
          <div
              class="hamburger-top"
              :class="[hamburger_color, {'close' : sidebar},{'black': currentPage === '/about-us'}]">
          </div>
          <div
              class="hamburger-center"
              :class="[hamburger_color, {'close' : sidebar},{'black': currentPage === '/about-us'}]"

          >
          </div>
          <div
              class="hamburger-bottom"
              :class="[hamburger_color, {'close' : sidebar},{'black': currentPage === '/about-us'}]"
          >
          </div>
        </div>
      </b-navbar-nav>
    </b-container>
    <b-sidebar
        id="sidebar"
        no-header
        right
        sidebar-class="vw-100"
        body-class="p-0 scroll-hide"
        class="custom"
        :class="{'opened': sidebar}"
        v-model="sidebar"
    >
      <b-container fluid class="pt-sm-3 px-sm-5 z-1036" v-if="sidebar">
        <b-navbar-brand @click="$router.push('/')" class="ml-sm-3 pl-sm-1" :class="[{'pl-1': currentPage === '/platform'}, {'pl-sm-0': currentPage === '/platform'}, {'pl-1' : currentPage === '/performance'},
          {'pl-sm-0': currentPage === '/performance'}, {'pl-1': currentPage === '/'}, {'pl-sm-0': currentPage === '/'}]">
          <svg :class="[ logo_color, {'red-forced' : sidebar}, {'dark': currentPage === '/about-us'}]"
               class="cursor-pointer"
               width="114" height="43" viewBox="0 0 114 43" xmlns="http://www.w3.org/2000/svg">
            <path d="M108.709 41.0716C107.312 41.0716 105.94 40.517 104.924 39.4787C102.262 36.8164 99.5796 34.0636 96.9852 31.4042C91.3042 25.576 85.4279 19.5498 79.3819 14.0781C76.6008 11.563 74.4506 10.5812 71.7063 10.5812C68.348 10.5812 65.1652 12.208 63.1932 14.9297C61.4787 17.2949 58.1714 17.8212 55.8062 16.1067C53.4409 14.3922 52.9147 11.0848 54.6292 8.71961C58.5844 3.25924 64.9671 0 71.7063 0C78.7736 0 83.5211 3.55631 86.4776 6.22991C92.3595 11.5516 97.9783 17.2695 103.419 22.8458V6.38835C103.419 3.46578 105.787 1.09773 108.709 1.09773C111.632 1.09773 114 3.46578 114 6.38835V35.781C114 37.9312 112.699 39.8692 110.707 40.6783C110.056 40.9443 109.38 41.0716 108.709 41.0716Z"/>
            <path d="M71.7062 42.1693C62.6528 42.1693 56.7369 36.0865 51.9838 31.2005C51.4123 30.6149 50.8493 30.032 50.2835 29.469L39.9738 19.1594V35.781C39.9738 37.9199 38.6866 39.8494 36.7089 40.667C34.7342 41.4875 32.4566 41.032 30.9459 39.5212L10.5812 19.1594V35.781C10.5812 38.7036 8.21318 41.0716 5.29061 41.0716C2.36805 41.0716 0 38.7036 0 35.781V6.38838C0.00282921 4.2495 1.29012 2.31998 3.26773 1.50234C5.24535 0.684698 7.52003 1.13737 9.03365 2.64817L29.3954 23.01V6.38838C29.3954 4.2495 30.6827 2.31998 32.6603 1.50234C34.638 0.681868 36.9126 1.13737 38.4263 2.64817L57.7667 21.9886C58.3665 22.5884 58.9663 23.2023 59.5689 23.8248C63.6232 27.9922 67.1229 31.5909 71.7062 31.5909C75.1494 31.5909 78.3803 29.8991 80.3466 27.0642C82.013 24.665 85.309 24.0681 87.7082 25.7345C90.1074 27.4009 90.7043 30.6969 89.0379 33.0961C85.094 38.7771 78.6151 42.1693 71.7062 42.1693Z"/>
          </svg>
        </b-navbar-brand>
        <b-navbar-nav
            class="mr-sm-3 mr-2"
        >
          <div
              class="hamburger mt-md-4 pt-md-2"
              v-b-toggle.sidebar
              :class="[{'mr-sm-3': currentPage === '/platform'}, {'mr-0': currentPage !== '/platform'}]"
          >
            <div
                class="hamburger-top"
                :class="[hamburger_color, {'close' : sidebar},{'black': currentPage === '/about-us'}]">
            </div>
            <div
                class="hamburger-center"
                :class="[hamburger_color, {'close' : sidebar},{'black': currentPage === '/about-us'}]"

            >
            </div>
            <div
                class="hamburger-bottom"
                :class="[hamburger_color, {'close' : sidebar},{'black': currentPage === '/about-us'}]"
            >
            </div>
          </div>
        </b-navbar-nav>
      </b-container>
      <b-container fluid class="px-sm-5 mx-sm-2 px-1">
        <b-row class="pt-sm-5 px-3 vw-100" align-h="center">
          <b-col sm="12" class="pt-4 w-100">
            <transition name="about">
              <div v-show="sidebar">
                <b-link :to="menu_link.about">
                  <h1 class="fs-40 font-brand text-primary mb-0 mt-4">
                                <span>
                                    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="2" y="37" width="35" height="35" transform="rotate(-90 2 37)" stroke="#B61F24" stroke-width="4"/>
                                        <path d="M17 27L23 18.8431L17 11" stroke="#B61F24" stroke-width="4"/>
                                    </svg>
                                </span>
                    ABOUT US
                  </h1>
                  <h6 class="text-dark mt-2">
                    THE MON SPORTS, 스포츠 경기의 시작과 끝을 완성합니다.
                  </h6>
                </b-link>
              </div>
            </transition>
          </b-col>
          <b-col sm="12" class="pt-5">
            <transition name="performance">
              <div v-show="sidebar">
                <b-link :to="menu_link.performance">
                  <h1 class="fs-40 font-brand text-primary">
                                <span>
                                    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="2" y="37" width="35" height="35" transform="rotate(-90 2 37)" stroke="#B61F24" stroke-width="4"/>
                                        <path d="M17 27L23 18.8431L17 11" stroke="#B61F24" stroke-width="4"/>
                                    </svg>
                                </span>
                    PERFORMANCE
                  </h1>
                  <h6 class="text-dark mt-2">
                    수많은 경험과 데이터로부터 도출된 노하우를 통해 최고의 퍼포먼스를 제공합니다.
                  </h6>
                </b-link>
              </div>
            </transition>
          </b-col>
          <b-col sm="12" class="pt-5">
            <transition name="platform">
              <div v-show="sidebar">
                <b-link :to="menu_link.platform">
                  <h1 class="fs-40 font-brand text-primary">
                                 <span>
                                    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="2" y="37" width="35" height="35" transform="rotate(-90 2 37)" stroke="#B61F24" stroke-width="4"/>
                                        <path d="M17 27L23 18.8431L17 11" stroke="#B61F24" stroke-width="4"/>
                                    </svg>
                                 </span>
                    PLATFORM
                  </h1>
                  <h6 class="text-dark mt-2">
                    기존 SPORTS EVENTS 현장의 낙후된 <br class="d-block d-sm-none">운영 시스템을 개선하고 새로운 스탠다드를 만듭니다.
                  </h6>
                </b-link>
              </div>
            </transition>
          </b-col>
          <b-col sm="12" class="pt-5">
            <transition name="contact">
              <div v-show="sidebar">
                <h1 @click="clickToContact" class="fs-40 font-brand text-primary cursor-pointer">
                                 <span>
                                    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="2" y="37" width="35" height="35" transform="rotate(-90 2 37)" stroke="#B61F24" stroke-width="4"/>
                                        <path d="M17 27L23 18.8431L17 11" stroke="#B61F24" stroke-width="4"/>
                                    </svg>
                                 </span>
                  CONTACT
                </h1>
                <h6 class="text-dark mt-2">
                  성공적인 SPORTS EVENTS를 위해 이제 우리와 함께 할 시간입니다.
                </h6>
              </div>
            </transition>
          </b-col>
        </b-row>
      </b-container>
      <b-row v-show="sidebar">
        <b-col sm="12" class="px-0 bg-transparent">
          <img class="w-100 dot-img mt-5" src="https://cyhcnk-studio-s3.s3.ap-northeast-2.amazonaws.com/themonstports-asset/menu-dots.png" alt="">
        </b-col>
      </b-row>
      <b-container fluid ref="contact" id="contact" class="bg-primary py-5 px-sm-5 px-2"  v-show="sidebar">
        <b-row class="vw-100 h-100 mt-5 pt-sm-5 px-3" align-h="center" align-v="start">
          <b-col sm="12">
            <h4 class="text-white fs-mobile-18">
              성공적인 SPORTS EVENTS를 위해 <br class="d-block d-sm-none">이제 우리와 함께 할 시간입니다.
            </h4>
          </b-col>
          <b-col sm="6" class="pr-sm-5">
            <div class="mt-5">
              <h6 class="font-weight-light text-white">성함</h6>
              <b-form-input
                  id="input-1"
                  v-model="form.name"
                  placeholder="성함을 입력해주세요."
                  required
                  class="bg-transparent text-white fs-16 font-weight-light border-1"
              ></b-form-input>
            </div>
            <div class="mt-4">
              <h6 class="font-weight-light text-white">기업/단체명</h6>
              <b-form-input
                  id="input-2"
                  v-model="form.corporation_name"
                  placeholder="기업 또는 단체명을 입력해주세요."
                  required
                  class="bg-transparent text-white fs-16 font-weight-light"
              ></b-form-input>
            </div>
            <div class="mt-4">
              <h6 class="font-weight-light text-white">연락처</h6>
              <b-form-input
                  id="input-3"
                  v-model="form.contact"
                  placeholder="이메일 또는 휴대폰 번호를 입력해주세요."
                  required
                  class="bg-transparent text-white fs-16 font-weight-light"
              ></b-form-input>
            </div>
          </b-col>
          <b-col sm="6" class="pl-sm-5 pr-sm-3">
            <div class="mt-5">
              <h6 class="font-weight-light text-white">문의 내용</h6>
              <b-form-textarea
                  id="textarea"
                  v-model="form.text"
                  placeholder="문의 내용을 입력해주세요."
                  rows="8"
                  class="bg-transparent text-white fs-16 font-weight-light pb-3"
              ></b-form-textarea>
            </div>
          </b-col>
          <b-col sm="6" class="mt-5 text-center">
            <b-button
                squared
                class="px-5 send-btn d-inline-flex border-1"
                @click="submit"
                :class="{'disabled': is_sending}"
            >
              <h5 v-show="!is_sending" class="font-weight-light px-3 mb-0">보내기</h5>
              <b-spinner size="sm" v-show="is_sending" class=""></b-spinner>
            </b-button>
          </b-col>
          <b-col sm="12" class="mt-5">
            <hr class="text-white mt-5" :class="[{'border-1': !isMobile}, {'bg-white': isMobile}]">
          </b-col>
          <b-col sm="12">
            <v-footer></v-footer>
          </b-col>
        </b-row>
      </b-container>
    </b-sidebar>
  </b-navbar>
</template>

<script>
import { EventBus } from '../../EventBus'
import VFooter from "./VFooter";

export default {
  name: 'GlobalNavigationBar',
  components: { VFooter },
  data() {
    return{
      isMobile: false,
      currentPage: null,
      currentSlide: null,
      is_sending: false,
      form: {
        name: '',
        corporation_name: '',
        contact: '',
        text: ''
      },
      logo_color: null,
      hamburger_pill: 'white',
      hamburger_color: 'white',
      sidebar: false,
      toggleOn: false,
      menu_link: {
        about: '/about-us',
        performance: '/performance',
        platform: '/platform'
      },
    }
  },
  methods: {
    submit(){
      let form = this.form;

      if(form.name && form.corporation_name && form.contact && form.text) {
        let that = this;
        this.is_sending = true;
        this.axios.post('/themon/contact', this.form).then(res=>{
          that.is_sending = false;
          alert('성공적으로 신청되었습니다.')
          form.name = '';
          form.corporation_name = '';
          form.contact = '';
          form.text = '';
        }).catch((error)=>{
          that.is_sending = false;
          alert('에러: 관리자에게 문의하세요. ' + error)
        })
      } else {
        alert('모든 항목을 기입해주세요.')
      }
    },
    to_contact(){
      let that = this;
      that.sidebar = true;
      const options = {
        container: '.b-sidebar-body'
      }
      setTimeout(()=>{
        this.$scrollTo(document.getElementById('contact'), 0, options)
      })
    },
    clickToContact() {
      const options = {
        container: '.b-sidebar-body'
      }
      this.$scrollTo(document.getElementById('contact'), 500, options)
    },
  },
  watch:{
    '$route.fullPath': function (val){
      this.currentPage = val
    },
    '$route.query.slide': function (val){
      this.currentSlide = val
      // console.log(val)
    }
  },
  created() {
    this.currentPage = this.$route.fullPath;
    let ua = navigator.userAgent;
    let is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
    if(is_ie) {
      this.is_ie = true;
    }

    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
      this.isMobile = true;
    }

  },
  mounted() {
    let that = this;
    EventBus.$on('gnb_bg_class', data => {
      that.logo_color = data
    })
    EventBus.$on('hamburger_color', data => {
      that.hamburger_color = data
    })
    EventBus.$on('open-contact', (data) => {
      that.sidebar = data
      that.to_contact()
    })
  }
}
</script>
