import Vue from 'vue';
import VueRouter from 'vue-router';
import GlobalNavigationBar from "./components/GlobalNavigationBar";

Vue.use(VueRouter); // 라우터

const router = new VueRouter({
    mode: 'history',
    routes: [

        {
            path: '/',
            components: {
                default: require('./pages/Home').default,
            },
        },
        {
            path: '/about-us',
            components: {
                default: require('./pages/About').default,
            }
        },
        {
            path: '/performance',
            components: {
                default: require('./pages/Performance').default,
            }
        },
        {
            path: '/platform',
            components: {
                default: require('./pages/Platform').default,
            }
        },
        {
            path: '/app/:id',
            components: {
                default: require('./pages/Application').default,
            },
        },
        // {
        //     path: '/app/:id',
        //     components: {
        //         default: require('./pages/App').default,
        //     }
        // },
    ],
});

export default router;
